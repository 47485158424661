import { gql } from 'graphql-request'
import { GenericBlock } from './generic'

export const externalImageFragment = gql`
  fragment externalImageFragment on ExternalImage {
    id
    url: image
    caption
  }
`

export interface ExternalImage extends GenericBlock<'ExternalImage'> {
  url: string
  caption: string
}
