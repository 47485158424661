import { gql } from 'graphql-request'

import { GenericBlock } from './generic'

export const formFragment = gql`
  fragment formFragment on FormBlock {
    id
    form {
      id
      title
      confirmationMessage
      confirmationType
      redirect {
        url
      }
      submitButtonLabel
      fields {
        __typename
        ... on Text {
          id
          name
          label
          required
        }
        ... on Number {
          id
          name
          label
          required
        }
        ... on Textarea {
          id
          name
          label
          required
        }
        ... on Email {
          id
          name
          label
          required
        }
        ... on Checkbox {
          id
          name
          label
          required
        }
        ... on Message {
          id
          message
        }
        ... on Select {
          id
          name
          label
          required
          options {
            label
            value
          }
        }
        ... on Date {
          id
          name
          label
          required
        }
      }
    }
  }
`

export interface Form extends GenericBlock<'FormBlock'> {
  id: string
  form: {
    id: string
    title: string
    confirmationMessage: string
    confirmationType: 'message' | 'redirect'
    redirect: {
      url: string
    }
    submitButtonLabel: string
    fields: {
      __typename:
        | 'Text'
        | 'Number'
        | 'Textarea'
        | 'Email'
        | 'Checkbox'
        | 'Message'
        | 'Select'
        | 'Date'
      id: string
      name: string
      label: string
      required: boolean
      options?: {
        label: string
        value: string
      }[]
      message?: any
    }[]
  }
}

export type Field = Form['form']['fields'][number]
export type FieldType = Field['__typename']
