import React, { useEffect, useState } from 'react'
import {
  Select as SelectBase,
  YStack,
  SelectProps as SelectPropsBase,
  isWeb,
  SelectTriggerProps,
  Theme,
  ThemeName,
  useTheme,
  ColorTokens,
} from '@mythical/ui'
import { LinearGradient } from '@tamagui/linear-gradient'
import { FormFieldContainer } from '../FormFieldContainer'
import { FormContainerBaseTypes } from '../FormContainerBaseTypes'
import { IconChevronDown, IconChevronUp, IconCheckCircle } from '../../Icon'

export type SelectProps = SelectPropsBase &
  FormContainerBaseTypes & {
    value?: string
    options: { label: string; value: string }[]
    width?: number | string
    placeholder?: string
    dropDownLabel?: string // above the items list
    fullWidth?: boolean
    triggerProps?: SelectTriggerProps
    placeholderTextColor?: ColorTokens
    theme?: ThemeName
  }

export function Select({
  id,
  value,
  theme = 'outline',
  options = [],
  width = 180,
  placeholder = '',
  triggerProps,
  dropDownLabel,
  required,
  error,
  helperText,
  helperTextProps,
  label,
  hideLabel,
  labelInline,
  labelProps,
  fullWidth,
  defaultValue,
  onValueChange,
  containerProps,
  placeholderTextColor,
  ...rest
}: SelectProps) {
  const fullOptions = [
    ...(placeholder ? [{ label: placeholder, value: '' }] : []),
    ...options,
  ]

  const currentTheme = useTheme({ name: theme })
  const [selectVal, setSelectVal] = useState<string>(
    value ?? defaultValue ?? ''
  )

  useEffect(() => {
    if (value !== selectVal) {
      setSelectVal(value ?? defaultValue ?? '')
    }
  }, [value])

  rest.size = rest.size || '$4'

  return (
    <Theme name={theme}>
      <FormFieldContainer
        id={id}
        error={error}
        required={required}
        labelProps={labelProps}
        label={label}
        size={rest.size}
        fullWidth={fullWidth}
        labelInline={labelInline}
        helperText={helperText}
        helperTextProps={helperTextProps}
        hideLabel={hideLabel}
        {...containerProps}
      >
        <SelectBase
          id={id}
          {...rest}
          value={selectVal}
          onValueChange={(val) => {
            setSelectVal(val)
            if (typeof onValueChange === 'function') {
              onValueChange(val)
            }
          }}
        >
          <SelectBase.Trigger
            minWidth={100}
            maxWidth={220}
            iconAfter={
              <IconChevronDown size={27} color={currentTheme.color.val} />
            }
            py="$2"
            br="$2"
            {...(fullWidth && { w: '100%', maxWidth: 'auto' })}
            {...(error && { borderColor: '$red7' })}
            {...triggerProps}
          >
            <SelectBase.Value
              flexShrink={1}
              numberOfLines={1}
              placeholder={
                value
                  ? options.find((o) => o.value === value)?.label
                  : placeholder
              }
              {...(placeholderTextColor &&
                value === '' && {
                  color: placeholderTextColor,
                })}
            />
          </SelectBase.Trigger>

          <SelectBase.Adapt platform="touch">
            <SelectBase.Sheet modal dismissOnSnapToBottom animation='normal'>
              <SelectBase.Sheet.Frame
                bg="$background"
                bw={1}
                boc="$backgroundHover"
                p="$3"
                pt="$4"
              >
                <SelectBase.Sheet.Handle bg="$backgroundHover" />
                <SelectBase.Sheet.ScrollView>
                  <SelectBase.Adapt.Contents />
                </SelectBase.Sheet.ScrollView>
              </SelectBase.Sheet.Frame>
              <SelectBase.Sheet.Overlay
                animation="lazy"
                enterStyle={{ opacity: 0 }}
                exitStyle={{ opacity: 0 }}
                bg="$background"
                opacity={0.7}
              />
            </SelectBase.Sheet>
          </SelectBase.Adapt>

          <SelectBase.Content zIndex={200000}>
            <SelectBase.ScrollUpButton
              ai="center"
              jc="center"
              pos="absolute"
              w="100%"
              h="$3"
              overflow="hidden"
            >
              <YStack zi={10}>
                <IconChevronUp color={currentTheme.color.val} size={20} />
              </YStack>
              <LinearGradient
                start={[0, 0]}
                end={[0, 1]}
                fullscreen
                colors={['$secondary9', 'transparent']}
                br="$7"
                top="$0.25"
              />
            </SelectBase.ScrollUpButton>

            <SelectBase.Viewport minWidth={200}>
              <SelectBase.Group
                bg="$background"
                {...(!isWeb && {
                  $sm: {
                    space: '$2',
                    borderRadius: '$2',
                  },
                })}
              >
                <SelectBase.Label
                  bg="$transparent"
                  color="$color"
                  fontWeight="600"
                >
                  {label}
                </SelectBase.Label>
                {fullOptions.map((item, i) => {
                  return (
                    <SelectBase.Item
                      borderRadius="$3"
                      index={i}
                      key={`${item.value}`}
                      value={item.value}
                    >
                      <SelectBase.ItemText flexShrink={1} color="$color">
                        {item.label}
                      </SelectBase.ItemText>
                      <SelectBase.ItemIndicator flexShrink={0} ml="auto">
                        <IconCheckCircle
                          size={16}
                          color={currentTheme.color.val}
                        />
                      </SelectBase.ItemIndicator>
                    </SelectBase.Item>
                  )
                })}
              </SelectBase.Group>
            </SelectBase.Viewport>

            <SelectBase.ScrollDownButton
              ai="center"
              jc="center"
              pos="relative"
              w="100%"
              h="$3"
              overflow="hidden"
            >
              <YStack zi={10}>
                <IconChevronDown size={20} color={currentTheme.color.val} />
              </YStack>
              <LinearGradient
                start={[0, 0]}
                end={[0, 1]}
                fullscreen
                colors={['transparent', '$secondary9']}
                br="$7"
                bottom="$0.25"
              />
            </SelectBase.ScrollDownButton>
          </SelectBase.Content>
        </SelectBase>
      </FormFieldContainer>
    </Theme>
  )
}
