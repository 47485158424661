import { gql } from 'graphql-request'

import { GenericBlock } from './generic'

export const pollFragment = gql`
  fragment pollFragment on Poll {
    id
    poll {
      id
      questions {
        ... on SingleChoice {
          id
          question
          answers {
            id
            answer
            percent
            yourVote
          }
        }
      }
      closingDate
    }
  }
`

export interface Poll extends GenericBlock<'Poll'> {
  id: string
  poll: {
    id: string
    questions: {
      id: string
      question: string
      answers: {
        id: string
        answer: string
        percent: number
        yourVote: boolean
      }[]
    }[]
    closingDate?: string
  }
}
