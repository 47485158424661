import { gql } from 'graphql-request'

import { GenericBlock } from './generic'

export const promoCodeFragment = gql`
  fragment promoCodeFragment on PromoCode {
    id
    codeTitle: title
    passwordTitle
    code
    password
  }
`

export interface PromoCode extends GenericBlock<'PromoCode'> {
  codeTitle: string
  passwordTitle: string
  password: string
  code: string
}
