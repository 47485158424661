import { gql } from 'graphql-request'
import { GenericBlock } from '../generic'
import { ExcludedPlatforms } from 'app/types/excludedPlatforms'

export const landingAllSeriesFragment = gql`
  fragment landingAllSeriesFragment on LandingAllSeries {
    id
    landingAllSeriesTitle: title
    landingAllSeriesSubtitle: subtitle
    collections {
      name
      subtitle
      slug
      thumbnail {
        id
        sizes {
          thumbnail {
            url
          }
        }
      }
      itemCount
    }
  }
`

export interface LandingAllSeries extends GenericBlock<'LandingAllSeries'> {
  landingAllSeriesTitle: string
  landingAllSeriesSubtitle: string
  collections: any[]
  excludedPlatforms: ExcludedPlatforms
}
