import { gql } from 'graphql-request'
import { GenericBlock } from '../generic'
import { contentFragments } from 'app/queries/content/all-content-list'
import { ExcludedPlatforms } from 'app/types/excludedPlatforms'

export const recommended = gql`
  fragment recommended on Recommended {
    id
    recommendedTitle: title
    content {
      ...content
    }
    collection {
      name
    }
    excludedPlatforms {
      ios
      android
      web
    }
  }
`

export interface Recommended extends GenericBlock<'Recommended'> {
  recommendedTitle: string
  content: any[]
  collection: {
    title: string
  }
  excludedPlatforms: ExcludedPlatforms
}
