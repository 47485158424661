import { gql } from 'graphql-request'

import { GenericBlock } from './generic'

export const imageFragment = gql`
  fragment imageFragment on ImageBlock {
    id
    image {
      id
      url
    }
    caption
  }
`

export interface Image extends GenericBlock<'ImageBlock'> {
  image: {
    id: string
    url: string
  }
  caption: string
}
