import {
  H3,
  Image,
  Stack,
  SizableText,
  XStack,
  YStack,
  isWeb,
} from '@mythical/ui'
import { IconListSmall, IconPlaylist } from 'app/components/primitive/Icon'
import React from 'react'
import { LinkCore } from 'solito/link'

export interface CollectionCardProps {
  image: string
  title: string
  itemCount: number
  type: 'collection' | 'playlist'
  href: string
}

const iconTypeMap = {
  playlist: IconPlaylist,
  collection: IconListSmall,
}

const CollectionCard = ({
  image,
  title,
  itemCount,
  href,
  type,
}: CollectionCardProps) => {
  const Icon = iconTypeMap[type]
  return (
    <LinkCore
      Component={YStack}
      componentProps={{ tag: 'a', pressStyle: { opacity: 0.7 } }}
      href={href}
    >
      <YStack
        w="100%"
        bg="$secondary9"
        overflow="hidden"
        elevation="$1"
        borderWidth={1}
        borderRadius="$2"
        borderColor="$secondary10"
      >
        <Stack overflow="hidden">
          <Stack
            w="100%"
            scale={1}
            hoverStyle={{
              scale: 1.2,
            }}
            backgroundColor="$secondary9"
            animation={[
              'quick',
              {
                scale: {
                  overshootClamping: true,
                },
              },
            ]}
            {...(isWeb ? { pb: '56.25%' } : { aspectRatio: 16 / 9 })}
          >
            <Image
              source={{ uri: image }}
              width="100%"
              height="100%"
              resizeMethod="scale"
              resizeMode="cover"
              pos={isWeb ? 'absolute' : 'relative'}
            />
          </Stack>
          <XStack
            alignItems="center"
            position="absolute"
            bottom={0}
            right={0}
            px="$2"
            pt="$1"
            pb="$1"
            borderTopLeftRadius="$2"
            zIndex="$1"
            bg="rgba(0,0,0,0.5)"
          >
            <Icon size={20} color="$primary6" />
            <SizableText fontSize="$2" fontWeight="500" color="$primary6">
              {itemCount}
            </SizableText>
          </XStack>
        </Stack>
        <YStack
          display="flex"
          flexShrink={1}
          flexGrow={1}
          alignItems="flex-start"
          space="$2"
          p="$4"
        >
          <H3
            fontFamily="$body"
            color="$primary10"
            lineHeight="$4"
            fontSize="$4"
          >
            {title}
          </H3>
        </YStack>
      </YStack>
    </LinkCore>
  )
}

export default CollectionCard
