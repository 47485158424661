import React from 'react'
import {
  FontSizeTokens,
  Label,
  Paragraph,
  SizeTokens,
  styled,
  ThemeableStack,
  ThemeableStackProps,
  YStack,
} from '@mythical/ui'
import { FormContainerBaseTypes } from './FormContainerBaseTypes'

type FormContainerProps = ThemeableStackProps &
  FormContainerBaseTypes & {
    id?: string
    size?: SizeTokens
    fullWidth?: boolean
  }

const StackContainer = styled(ThemeableStack, {
  variants: {
    fullWidth: {
      true: {
        width: '100%',
      },
    },
    labelInline: {
      true: {
        flexDirection: 'row',
        space: true,
        alignItems: 'center',
      },
    },
  } as const,
})

export function FormFieldContainer({
  label,
  children,
  helperText,
  id,
  size,
  labelProps,
  hideLabel,
  required,
  error,
  helperTextProps,
  ...rest
}: FormContainerProps) {
  return (
    <StackContainer space={!!rest.labelInline} {...rest}>
      {label && (
        <Label
          htmlFor={id}
          fontSize={(size as FontSizeTokens) || '$3'}
          textTransform="uppercase"
          {...labelProps}
          color={error ? '$red7' : '$color'}
          width={rest.labelInline ? 150 : undefined}
          justifyContent={rest.labelInline ? 'flex-end' : undefined}
          pb="$2"
          {...(hideLabel && {
            position: 'absolute',
            width: 1,
            height: 1,
            zIndex: -10000,
            overflow: 'hidden',
            opacity: 0.00000001,
            pointerEvents: 'none',
          })}
        >
          {label} {required && ` *`}
        </Label>
      )}
      <YStack>
        {children}
        {helperText && (
          <Paragraph
            paddingLeft={'$2'}
            marginTop={'$2'}
            size={size as FontSizeTokens}
            {...helperTextProps}
            color={error ? '$red7' : undefined}
          >
            {helperText}
          </Paragraph>
        )}
      </YStack>
    </StackContainer>
  )
}
