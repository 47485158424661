import { gql } from 'graphql-request'
import { GenericBlock } from '../generic'
import { ExcludedPlatforms } from 'app/types/excludedPlatforms'

export const comingUp = gql`
  fragment comingUp on ComingUpHomepage {
    id
    comingUpTitle: title
    comingUp {
      id
      name
      startDate
      endDate
      degree
      thumbnail {
        sizes {
          thumbnail {
            url
          }
        }
      }
    }
    excludedPlatforms {
      ios
      android
      web
    }
  }
`

export interface ComingUp extends GenericBlock<'ComingUpHomepage'> {
  comingUpTitle: string
  comingUp: {
    id: string
    name: string
    startDate: string
    endDate: string
    degree: string
    thumbnail: {
      sizes: {
        thumbnail: {
          url: string
        }
      }
    }
  }[]
  excludedPlatforms: ExcludedPlatforms
}
