import { gql } from 'graphql-request'
import { GenericBlock } from './generic'

export const richTextFragment = gql`
  fragment richTextFragment on RichText {
    id
    content
  }
`

export interface RichText extends GenericBlock<'RichText'> {
  content: any
}
