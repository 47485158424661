import { gql } from 'graphql-request'
import { GenericBlock } from '../generic'
import { ExcludedPlatforms } from 'app/types/excludedPlatforms'

export const categoryLinksFragment = gql`
  fragment categoryLinks on CategoryLinks {
    id
    links {
      name
      slug
      type
    }
    excludedPlatforms {
      ios
      android
      web
    }
  }
`

export interface CategoryLinks extends GenericBlock<'CategoryLinks'> {
  links: {
    name: string
    slug: string
    type: string
  }[]
  excludedPlatforms: ExcludedPlatforms
}