import { gql } from 'graphql-request'

import { GenericBlock } from './generic'

export const collectableRedemptionFormFragment = gql`
  fragment collectableRedemptionFormFragment on CollectableRedemptionForm {
    id
    html
  }
`

export interface CollectableRedemptionForm
  extends GenericBlock<'CollectableRedemptionForm'> {
  id: string
  html: string
}
