import React, { useCallback, useRef } from 'react'
import { SwiperProps, SwiperRef } from 'swiper/react'
import { Swiper } from 'swiper/types'

export const useSwiperControls = () => {
  const sliderRef = useRef<SwiperRef>(null)
  const [activeSlide, setActiveSlide] = React.useState(0)
  const [hasNext, setHasNext] = React.useState(false)
  const [hasPrev, setHasPrev] = React.useState(false)

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slidePrev()
  }, [])

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slideNext()
  }, [])

  const slideTo = useCallback((index: number) => {
    if (!sliderRef.current) return
    sliderRef.current.swiper.slideTo(index)
  }, [])

  const updateControls = (swiper: Swiper) => {
    setHasNext(!swiper.isEnd)
    setHasPrev(!swiper.isBeginning)
  }

  const swiperProps: SwiperProps & {
    ref: React.RefObject<SwiperRef>
  } = {
    onBeforeInit: (swiper) => {
      updateControls(swiper)
    },
    onResize(swiper) {
      updateControls(swiper)
    },
    onSnapIndexChange: (swiper) => {
      updateControls(swiper)
    },
    onSlideChange: (swiper) => {
      setActiveSlide(swiper.activeIndex)
    },
    ref: sliderRef,
  }

  return { swiperProps, handlePrev, handleNext, hasPrev, hasNext, activeSlide, slideTo }
}
