import { gql } from 'graphql-request'
import { GenericBlock } from '../generic'
import { contentFragments } from 'app/queries/content/all-content-list'
import { ExcludedPlatforms } from 'app/types/excludedPlatforms'

export const popular = gql`
  fragment popular on Popular {
    id
    popularTitle: title
    content {
      ...content
    }
    excludedPlatforms {
      ios
      android
      web
    }
  }
`

export interface Popular extends GenericBlock<'Popular'> {
  popularTitle: string
  content: any[]
  excludedPlatforms: ExcludedPlatforms
}
