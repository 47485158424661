import { gql } from 'graphql-request'
import { GenericBlock } from './generic'

export const featuredFragment = gql`
  fragment featuredFragment on Featured {
    id
    featuredCollection {
      id
    }
    maxPosts
  }
`

export interface Featured extends GenericBlock<'Featured'> {
  featuredCollection: {
    id: string
  }
  maxPosts: number
}
