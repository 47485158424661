import { ExcludedPlatforms } from 'app/types/excludedPlatforms'
import { gql } from 'graphql-request'

import { GenericBlock } from './generic'

export const productGridFragment = gql`
  fragment productGridFragment on ProductGrid {
    id
    mainTitle: title
    titleHighlight
    ctaLabel
    ctaHref
    products {
      id
      title
      description
      price
      discount
      href
      ctaLabel
      image {
        id
        url
      }
    }
    excludedPlatforms {
      ios
      android
      web
    }
  }
`

export interface ProductGrid extends GenericBlock<'ProductGrid'> {
  mainTitle?: string
  titleHighlight?: string
  ctaLabel?: string
  ctaHref?: string
  products: {
    id: string
    title?: string
    description?: string
    price: number
    discount?: number
    href: string
    ctaLabel?: string
    image: {
      id: string
      url: string
    }
  }[]
  excludedPlatforms: ExcludedPlatforms
}
