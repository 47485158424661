import { gql } from 'graphql-request'
import { GenericBlock } from '../generic'
import { ExcludedPlatforms } from 'app/types/excludedPlatforms'

export const allSeries = gql`
  fragment allSeries on AllSeries {
    id
    title
    collections {
      name
      subtitle
      slug
      thumbnail {
        id
        sizes {
          thumbnail {
            url
          }
        }
      }
      itemCount
    }
  }
`

export interface AllSeries extends GenericBlock<'AllSeries'> {
  title: string
  collections: any[]
  excludedPlatforms: ExcludedPlatforms
}
