import { Tier } from 'app/queries/tiers/member-tiers'
import { ExcludedPlatforms } from 'app/types/excludedPlatforms'
import { gql } from 'graphql-request'

import { GenericBlock } from './generic'

export const callToActionFragment = gql`
  fragment callToActionFragment on CallToAction {
    id
    ctaTitle: title
    ctaLabel
    ctaHref
    displaySize
    excludedPlatforms {
      ios
      android
      web
    }
    excludedTiers {
      id
      name
    }
  }
`

export interface CallToAction extends GenericBlock<'CallToAction'> {
  ctaTitle: string
  ctaLabel: string
  ctaHref: string
  displaySize: 'md' | 'lg'
  excludedPlatforms: ExcludedPlatforms
  excludedTiers: Pick<Tier, 'id' | 'name'>[]
}
