import { ExcludedPlatforms } from 'app/types/excludedPlatforms'
import { gql } from 'graphql-request'
import { GenericBlock } from './generic'

export const faqsFragment = gql`
  fragment faqsFragment on Faqs {
    id
    faqs {
      id
      question
      answer
      excludedPlatforms {
        ios
        android
        web
      }
    }
  }
`

export interface FAQs extends GenericBlock<'Faqs'> {
  faqs: {
    id: string
    excludedPlatforms: ExcludedPlatforms
    question: string
    answer: string
  }[]
}
