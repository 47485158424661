import { ExcludedPlatforms } from 'app/types/excludedPlatforms'
import { gql } from 'graphql-request'

import { GenericBlock } from './generic'

export const promoBanner = gql`
  fragment promoBanner on PromoBanner {
    text
    ctaLabel
    ctaLink
    excludedPlatforms {
      ios
      android
      web
    }
  }
`

export interface PromoBanner extends GenericBlock<'PromoBanner'> {
  text: any[]  
  ctaLabel?: string
  ctaLink?: string
  excludedPlatforms: ExcludedPlatforms
}

