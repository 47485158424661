import { ExcludedPlatforms } from 'app/types/excludedPlatforms'
import { gql } from 'graphql-request'

import { GenericBlock } from './generic'

export const bannerFragment = gql`
  fragment bannerFragment on Banner {
    id
    bannerTitle: title
    description
    ctaLabel
    href
    theme
    reverse
    bannerImage: image {
      id
      url
    }
    secondaryTitle
    secondaryDescription
    secondaryCtaLabel
    secondaryHref
    secondaryBannerImage: secondaryImage {
      id
      url
    }
    excludedPlatforms {
      ios
      android
      web
    }
  }
`

export interface Banner extends GenericBlock<'Banner'> {
  bannerTitle: string
  description: string
  ctaLabel: string
  href: string
  reverse: boolean
  theme: 'primary' | 'secondary' | 'noFillAlt' | 'sideBySide' | 'fullWidthHover'
  bannerImage: {
    id: string
    url: string
  }
  secondaryTitle?: string
  secondaryDescription?: string
  secondaryCtaLabel?: string
  secondaryHref?: string
  secondaryBannerImage?: {
    id: string
    url: string
  }
  excludedPlatforms?: ExcludedPlatforms
}
