import { gql } from 'graphql-request'
import { GenericBlock } from '../generic'
import { ExcludedPlatforms } from 'app/types/excludedPlatforms'

export const landingSingleSeriesFragment = gql`
  fragment landingSingleSeriesFragment on LandingSingleSeries {
    id
    seriesTitle: title
    seriesSubtitle: subtitle
    hideInfo
    content {
      ...content
    }
    collection {
      name
      subtitle
      slug
      thumbnail {
        id
        sizes {
          thumbnail {
            url
          }
        }
      }
      itemCount
    }
    excludedPlatforms {
      ios
      android
      web
    }
  }
`

export interface LandingSingleSeries extends GenericBlock<'LandingSingleSeries'> {
  seriesTitle: string
  seriesSubtitle: string
  hideInfo: boolean
  content: any[]
  collection: any
  excludedPlatforms: ExcludedPlatforms
}
