import { Stack, styled, useMedia } from '@mythical/ui'
import React from 'react'

import CollectionCard, { CollectionCardProps } from '../CollectionCard'
import CollectionCardCarousel from './CollectionCardCarousel'

type CollectionCardGridProps = {
  items: CollectionCardProps[]
  carouselMobile?: boolean
}

const CollectionCardWrapper = styled(Stack, {
  name: 'CollectionCardWrapper',
  width: '100%',
  p: '$2',
  $gtXs: {
    width: '50%',
  },
  $gtSm: {
    width: '33.333%',
  },
  $gtLg: {
    width: '25%',
  },
})

const CollectionCardGrid = ({
  items,
  carouselMobile = false,
}: CollectionCardGridProps) => {
  const media = useMedia()

  if (media.xs && carouselMobile) {
    return <CollectionCardCarousel items={items} />
  }

  return (
    <Stack flex={1} fd="row" ai="flex-start" flexWrap="wrap" m="$-2">
      {items.map((item) => (
        <CollectionCardWrapper key={item.href}>
          <CollectionCard {...item} />
        </CollectionCardWrapper>
      ))}
    </Stack>
  )
}

export default CollectionCardGrid
