import { gql } from 'graphql-request'

import { GenericBlock } from './generic'

export const spotifyEmbedFragment = gql`
  fragment spotifyEmbedFragment on Spotify {
    id
    spotifyId
  }
`

export interface SpotifyEmbed extends GenericBlock<'Spotify'> {
  spotifyId: string
}
