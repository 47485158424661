import { gql } from 'graphql-request'
import { GenericBlock } from '../generic'
import { contentFragments } from 'app/queries/content/all-content-list'
import { ExcludedPlatforms } from 'app/types/excludedPlatforms'

export const continueWatching = gql`
  fragment continueWatching on ContinueWatching {
    id
    continueWatchingTitle: title
    content {
      ...content
    }
    timeMap {
      secondsWatched
      contentId
    }
    excludedPlatforms {
      ios
      android
      web
    }
  }
`

export interface ContinueWatching extends GenericBlock<'ContinueWatching'> {
  continueWatchingTitle: string
  content: any[]
  timeMap: {
    secondsWatched: number
    contentId: string
  }[]
  excludedPlatforms: ExcludedPlatforms
}
