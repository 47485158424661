import { contentFragments } from 'app/queries/content/all-content-list'
import { Banner, bannerFragment } from './banner'
import {
  CollectableRedemptionForm,
  collectableRedemptionFormFragment,
} from './collectable-redemption-form'
import { ExternalImage, externalImageFragment } from './external-image'
import { FAQs, faqsFragment } from './faqs'
import { Featured, featuredFragment } from './featured'
import { GenericBlock } from './generic'
import { AllSeries, allSeries } from './homepage/all-series'
import { CategoryLinks, categoryLinksFragment } from './homepage/category-links'
import { ComingUp, comingUp } from './homepage/coming-up'
import {
  ContinueWatching,
  continueWatching,
} from './homepage/continue-watching'
import {
  HomepageFeatured,
  homepageFeaturedFragment,
} from './homepage/homepage-featured'
import { Latest, latest } from './homepage/latest'
import { NextUp, nextUp } from './homepage/next-up'
import { Popular, popular } from './homepage/popular'
import { Recommended, recommended } from './homepage/recommended'
import { Image, imageFragment } from './image'
import { Poll, pollFragment } from './poll'
import { PromoCode, promoCodeFragment } from './promo-code'
import { RichText, richTextFragment } from './rich-text'
import { SpotifyEmbed, spotifyEmbedFragment } from './spotify-embed'
import { Video, videoFragment } from './video'
import { PromoBanner, promoBanner } from './promo-banner'
import { ImageCarousel, imageCarouselFragment } from './image-carousel'
import { CallToAction, callToActionFragment } from './call-to-action'
import { Quiz, quizFragment } from './homepage/quiz'
import { ProductGrid, productGridFragment } from './product-grid'
import { Form, formFragment } from 'app/blocks/form'
import { LandingAllSeries, landingAllSeriesFragment } from './landing/landing-all-series'
import { LandingSingleSeries, landingSingleSeriesFragment } from './landing/landing-single-series'
import { LandingHero, landingHeroFragment } from './landing/landing-hero'
import { LandingCollectibles, landingCollectiblesFragment } from './landing/landing-collectibles'
import { RedeemCollectable, redeemCollectableFragment } from './redeem-collectable'

export const postContentBlocks = `
  ${richTextFragment}
  ${videoFragment}
  ${imageFragment}
  ${externalImageFragment}
  ${spotifyEmbedFragment}
  ${promoCodeFragment}
  ${pollFragment}
  ${imageCarouselFragment}
  ${productGridFragment}
  ${formFragment}
`

export type PostContentBlock =
  | RichText
  | Video
  | Image
  | ExternalImage
  | SpotifyEmbed
  | PromoCode
  | Poll
  | ImageCarousel
  | ProductGrid
  | Form

export const pageContentBlocks = `
  ${richTextFragment}
  ${videoFragment}
  ${imageFragment}
  ${bannerFragment}
  ${featuredFragment}
  ${externalImageFragment}
  ${faqsFragment}
  ${collectableRedemptionFormFragment}
  ${productGridFragment}
  ${formFragment}
`

export type PageContentBlock =
  | RichText
  | Video
  | Image
  | Banner
  | Featured
  | ExternalImage
  | FAQs
  | CollectableRedemptionForm
  | GenericBlock<'SignupConfirmation'>
  | ProductGrid
  | Form
  | RedeemCollectable


export const landingContentBlocks = `
  ${contentFragments}
  ${bannerFragment}
  ${landingAllSeriesFragment}
  ${landingSingleSeriesFragment}
  ${landingHeroFragment}
  ${promoBanner}
  ${quizFragment}
  ${landingCollectiblesFragment}
`

export type LandingContentBlock =
  | Banner
  | LandingAllSeries
  | LandingSingleSeries
  | LandingHero
  | LandingCollectibles
  | PromoBanner

export const homepageContentBlocks = `
  ${contentFragments}
  ${bannerFragment}
  ${allSeries}
  ${categoryLinksFragment}
  ${comingUp}
  ${continueWatching}
  ${homepageFeaturedFragment}
  ${latest}
  ${popular}
  ${nextUp}
  ${recommended}
  ${promoBanner}
  ${quizFragment}
  ${productGridFragment}
`

export type HomepageContentBlock =
  | AllSeries
  | Banner
  | CategoryLinks
  | ComingUp
  | ContinueWatching
  | HomepageFeatured
  | Latest
  | NextUp
  | Popular
  | Recommended
  | PromoBanner
  | Quiz
  | ProductGrid

export const merchPageContentBlocks = `
  ${bannerFragment}
  ${callToActionFragment}
  ${productGridFragment}
  ${promoCodeFragment}
`

export type MerchPageContentBlock = Banner | CallToAction | ProductGrid | PromoCode

export const collectiblePageContentBlocks = `
  ${richTextFragment}
  ${imageFragment}
  ${callToActionFragment}
  `

export type CollectiblePageContentBlock = Image | RichText | CallToAction | RedeemCollectable
