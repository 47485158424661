import { gql } from 'graphql-request'
import { GenericBlock } from './generic'

export const imageCarouselFragment = gql`
  fragment imageCarouselFragment on ImageCarousel {
    id
    images {
      id
      url
      filename
    }
  }
`

export interface IImage {
  id: string
  url: string
  filename: string
}

export interface ImageCarousel extends GenericBlock<'ImageCarousel'> {
  id: string
  images: IImage[]
}
