import { gql } from 'graphql-request'
import { GenericBlock } from '../generic'
import { Video, videoFragment } from '../video'

export const landingHeroFragment = gql`
  ${videoFragment}
  fragment landingHeroFragment on LandingHero {
    id
    mainTitle
    mainSubtitle
    heroVideo {
      title
      subtitle
      ctaLabel
      thumbnail {
          url
          sizes {
            thumbnail {
              url
            }
          }
        }
      video {
        ...videoFragment
      }
    }
  }
`

export interface LandingHero extends GenericBlock<'LandingHero'> {
  mainTitle: string
  mainSubtitle: string
  heroVideo: {
    title: string
    subtitle: string
    ctaLabel: string
    video: Video
    thumbnail: {
      url: string
      sizes: {
        thumbnail: {
          url: string
        }
      }
    }
  }
}
