import { gql } from 'graphql-request'
import { GenericBlock } from '../generic'
import { contentFragments } from 'app/queries/content/all-content-list'
import { ExcludedPlatforms } from 'app/types/excludedPlatforms'

export const latest = gql`
  fragment latest on Latest {
    id
    latestTitle: title
    content {
      ...content
    }
    excludedPlatforms {
      ios
      android
      web
    }
  }
`

export interface Latest extends GenericBlock<'Latest'> {
  latestTitle: string
  content: any[]
  excludedPlatforms: ExcludedPlatforms
}
