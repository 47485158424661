import React, { memo } from 'react'
import { Path } from 'react-native-svg'

import { IconBase } from '../IconBase'
import { IconProps } from '../IconProps'
import { themed } from '../themed'

const Icon = (props) => {
  const { color = 'black', size = 24, ...otherProps } = props
  return (
    <IconBase
      width={size}
      height={size}
      viewBox="0 0 27 27"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...otherProps}
    >
      <Path
        d="m6.75 15.918 1.586 1.586 5.164-5.152 5.164 5.152 1.586-1.586-6.75-6.75-6.75 6.75Z"
        fill={`${color}`}
      />
    </IconBase>
  )
}

Icon.displayName = 'IconChevronUp'

export const IconChevronUp = memo<IconProps>(themed(Icon))
