import { gql } from 'graphql-request'

import { GenericBlock } from '../generic'
import { ExcludedPlatforms } from 'app/types/excludedPlatforms'

export const landingCollectiblesFragment = gql`
  fragment landingCollectiblesFragment on LandingCollectibles {
    id
    mainTitle: title
    mainSubtitle: subtitle
    collectibles {
      id
      title
      quarter
      hasContent
      slug
      thumbnail {
        url
        sizes {
          square {
            url
          }
        }
      }
    }
    excludedPlatforms {
      ios
      android
      web
    }
  }
`

export interface LandingCollectibles
  extends GenericBlock<'LandingCollectibles'> {
  mainTitle: string
  mainSubtitle: string
  collectibles: {
    id: string
    title: string
    slug: string
    quarter: string
    hasContent: boolean
    thumbnail: {
      url: string
      sizes: {
        square: {
          url: string
        }
      }
    }
  }[]
  excludedPlatforms: ExcludedPlatforms
}
